var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-form',{ref:"formSubUser",staticClass:"border-all-admin-add--user q-px-sm",attrs:{"data-cy":"user-form-new-sub-user"},on:{"submit":_vm.confirmFormData}},[_c('div',{staticClass:"row full-width q-px-sm"},[_c('div',{staticClass:"col-6 q-py-sm q-px-sm"},[_c('q-input',{staticClass:"q-my-md",attrs:{"data-cy":"user-form-input-name","dense":"","rounded":"","outlined":"","placeholder":"Nome e sobrenome","bg-color":"white","clearable":"","counter":_vm.user.name ? true : false,"rules":[
          function (val) { return !!val || 'Campo é obrigatório'; },
          function (val) { return !val.match(/[^\w\s]/gi) ||
            'Nome só pode conter caracteres alfanuméricos'; },
          function (val) { return val.length <= 30 || 'Nome pode conter no máximo 30 caracteres'; } ]},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}}),_c('q-input',{attrs:{"data-cy":"user-form-input-password","dense":"","rounded":"","outlined":"","placeholder":"Senha","bg-color":"white","type":"password","clearable":"","counter":_vm.user.password ? true : false,"rules":[
          function (val) { return !!val || 'Campo é obrigatório'; },
          function (val) { return /^(?=.*\d)(?=.*[A-Z])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{12,}$/.test(
              val
            ) ||
            'Sua senha deve ter no mínimo 12 caracteres, entre eles um número, uma letra maiúscula e um caractere especial'; } ]},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('div',{attrs:{"align":"center"}},[_c('q-btn',{staticStyle:{"height":"40px"},attrs:{"data-cy":"user-form-save-button-new-user","color":"primary","type":"submit","label":"Salvar","rounded":"","unelevated":"","disable":_vm.loadingFormCreate}})],1)],1),_c('div',{staticClass:"col-6 q-py-sm q-px-sm"},[_c('q-input',{staticClass:"q-my-md",attrs:{"data-cy":"user-form-input-email","dense":"","rounded":"","outlined":"","type":"email","placeholder":"E-mail","bg-color":"white","clearable":"","rules":[function (val) { return !!val || 'Campo é obrigatório'; }]},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('q-select',{staticClass:"q-my-md",staticStyle:{"padding-bottom":"3px"},attrs:{"data-cy":"user-form-select-type-user","dense":"","rounded":"","outlined":"","options":_vm.typesUsers.typeUser,"bg-color":"white"},model:{value:(_vm.option),callback:function ($$v) {_vm.option=$$v},expression:"option"}}),_c('div',{attrs:{"align":"center"}},[_c('q-btn',{staticStyle:{"height":"40px"},attrs:{"color":"grey","label":"Cancelar","outline":"","unelevated":"","rounded":""},on:{"click":_vm.cancelForm}})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }