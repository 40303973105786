var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import UserHeaderContent from './UserControlHeaderContent.vue';
import Modal from '@/components/Modal.vue';
import IconAccessToken from '@/components/Icons/IconAccessToken.vue';
import Vue from 'vue';
import { connect } from '@/overmind';
import Tooltip from 'components/Tooltip.vue';
import { copyToClipboard } from 'quasar';
import { mixpanelTracking } from '@/services/mixpanel';
let UsersAdminPanelHeader = class UsersAdminPanelHeader extends Vue {
    constructor() {
        super(...arguments);
        this.showAllowedLimitErrorMessage = false;
        this.title = 'Gerenciar usuários';
        this.showModal = false;
        this.search = '';
        this.copied = false;
        this.copiedIntegration = false;
        this.additionalUsersSolicitationWaiting = false;
    }
    handleOpenFormAddUser() {
        if (!this.canAddUser) {
            this.showAllowedLimitErrorMessage = true;
            this.tryCreateNotification();
        }
        else {
            this.showAllowedLimitErrorMessage = false;
            this.openFormAddUser();
        }
    }
    searchUser(search) {
        return search;
    }
    openFormAddUser() {
        return true;
    }
    createNegativeCnpjs() {
        /* @ts-ignore */
        return this.negativeCnpjsSelected;
    }
    openModal() {
        this.showModal = true;
        this.$emit('modalNegativeCnpjs');
    }
    onChangeNegativeCnpjs(currVal) {
        /* @ts-ignore */
        this.negativeCnpjsSelected = currVal;
    }
    clearCnpjs() {
        /* @ts-ignore */
        this.negativeCnpjsSelected = [];
        /* @ts-ignore */
        this.keyAdminNegativeCnpjs += 1;
    }
    setNegativeCnpjs(cnpjs) {
        /* @ts-ignore */
        this.negativeCnpjsSelected = cnpjs;
    }
    canDisabledBtn() {
        return (this.allowedUsers === 0 ||
            this.fetchingUsers ||
            ['manager', 'prospector', 'coordinator'].includes(this.userRole));
    }
    async copyAccessToken() {
        await this.$emit('refreshAccessToken');
        this.copied = true;
        setTimeout(() => {
            this.copied = false;
        }, 3000);
    }
    async copyIntegrationToken() {
        try {
            const res = await Vue.axios.post('/auth/generate_integration_token/');
            const text = JSON.stringify(res.data);
            copyToClipboard(text);
            this.copiedIntegration = true;
            setTimeout(() => {
                this.copiedIntegration = false;
            }, 2000);
        }
        catch (e) {
            this.$q.notify({
                message: 'Ocorreu um problema em sua solicitação. Entre em contato com o suporte',
                type: 'error',
            });
        }
    }
    async tryCreateNotification() {
        // @ts-ignore
        await this.actions.tryToRegisterNewAdditionalUser();
    }
    get allowedUsersText() {
        return this.allowedUsers >= 1000
            ? ''
            : ` de <strong>${this.allowedUsers}</strong>`;
    }
    async additionalUsersSolicitation() {
        this.additionalUsersSolicitationWaiting = true;
        try {
            mixpanelTracking('Gerenciamento de usuários: Clicou em "Adicionar usuário extra" no modal');
            await Vue.axios.post('/account_plan/notify_change_plan_solicitation', {
                type: 'additional_users_purchase',
            });
            this.$q.notify({
                message: 'Recebemos sua solicitação de usuário extra. Nosso time entratá em contato com você em breve!',
                type: 'success',
            });
            this.showAllowedLimitErrorMessage = false;
        }
        catch (e) {
            this.$q.notify({
                message: 'Ocorreu um problema em sua solicitação. Entre em contato com o suporte',
                type: 'error',
            });
        }
        this.additionalUsersSolicitationWaiting = false;
    }
};
__decorate([
    Prop({ default: () => [] })
], UsersAdminPanelHeader.prototype, "users", void 0);
__decorate([
    Prop({ default: 0 })
], UsersAdminPanelHeader.prototype, "managedUsers", void 0);
__decorate([
    Prop({ default: '' })
], UsersAdminPanelHeader.prototype, "userRole", void 0);
__decorate([
    Prop({ default: 0 })
], UsersAdminPanelHeader.prototype, "numberUsers", void 0);
__decorate([
    Prop({ default: 0 })
], UsersAdminPanelHeader.prototype, "allowedUsers", void 0);
__decorate([
    Prop({ default: false })
], UsersAdminPanelHeader.prototype, "canAddUser", void 0);
__decorate([
    Prop({ default: false })
], UsersAdminPanelHeader.prototype, "fetchingUsers", void 0);
__decorate([
    Emit('searchUser')
], UsersAdminPanelHeader.prototype, "searchUser", null);
__decorate([
    Emit('openFormAddUser')
], UsersAdminPanelHeader.prototype, "openFormAddUser", null);
__decorate([
    Emit('createNegativeCnpjs')
], UsersAdminPanelHeader.prototype, "createNegativeCnpjs", null);
__decorate([
    Watch('negativeCnpjs')
], UsersAdminPanelHeader.prototype, "onChangeNegativeCnpjs", null);
UsersAdminPanelHeader = __decorate([
    Component(connect(({ actions }) => ({
        actions: actions.users,
    }), {
        name: 'UsersAdminPanelHeader',
        components: {
            UserHeaderContent,
            Modal,
            IconAccessToken,
            Tooltip,
        },
    }))
], UsersAdminPanelHeader);
export default UsersAdminPanelHeader;
